import { useState } from 'react'
import { useRecordContext, useRedirect, useNotify } from 'react-admin'
import { Switch, FormControlLabel } from '@mui/material'
import get from 'lodash/get'

import useUpdateDataProvider from '../hooks/useUpdateDataProvider'

import { COLLECTION_STATUSES } from './constants'

const CollectionCodeStatusField = props => {
  const { disabled } = props
  const record = useRecordContext()
  const updateDataProvider = useUpdateDataProvider()
  const [status, setStatus] = useState(record.status)
  const notify = useNotify()
  const redirectTo = useRedirect()

  const handleChange = async () => {
    const data = {
      status:
        status === COLLECTION_STATUSES.ACTIVE
          ? COLLECTION_STATUSES.INACTIVE
          : COLLECTION_STATUSES.ACTIVE,
    }

    setStatus(data.status)
    const response = await updateDataProvider('collection-codes', {
      id: record.id,
      data,
    })

    if (get(response, 'data.status', null)) {
      setStatus(response.data.status)
      notify('Collection updated', { type: 'success' })
      redirectTo('/collection-codes')
    }
  }

  return [COLLECTION_STATUSES.ACTIVE, COLLECTION_STATUSES.INACTIVE].includes(
    record.status,
  ) ? (
    <FormControlLabel
      control={
        <Switch
          checked={status === COLLECTION_STATUSES.ACTIVE}
          onChange={handleChange}
          disabled={disabled}
        />
      }
      label={status}
    />
  ) : (
    status
  )
}

export default CollectionCodeStatusField

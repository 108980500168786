import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ReferenceField,
  DateField,
  FunctionField,
  Pagination,
  TextInput,
  SelectInput,
  TabbedShowLayout,
  useRecordContext,
  SimpleForm,
  ArrayInput,
  useCreate,
  useRefresh,
  useNotify,
  Toolbar,
  SaveButton,
  SimpleFormIterator,
  RefreshButton,
  useUpdate,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useState } from 'react'

import FilterComponent from '../../components/Filter'

const HOMEPAGE_TYPE = {
  homepage: 'Homepage',
  podcourse: 'Podcourse',
}

const SEGMENT_TYPES = {
  guest_user: 'Guest Users',
  free_member: 'Free Members',
  premium_with_credit: 'Premium with credits',
  premium_without_credit: 'Premium without credits',
}

const SECTION_TYPES = [
  { id: 'homepage', name: 'Homepage' },
  { id: 'podcourse', name: 'Podcourse' },
]

const RECOMMEND_TYPES = [
  { id: 'single', name: 'Single Category' },
  { id: 'multiple', name: 'Multiple Category' },
]

const HomepageSegmentPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 100, 1000]} {...props} />
)

const HomepageSegmentFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <SelectInput
      source="type"
      choices={[
        { id: 'homepage', name: 'Homepage' },
        { id: 'podcourse', name: 'Podcourse' },
      ]}
      alwaysOn
      allowEmpty={true}
    />
  </FilterComponent>
)

const SectionDefaultAssignToolbar = ({ selectedRecord, setSelectedRecord }) => {
  const [create] = useCreate()
  const [update] = useUpdate()
  const refresh = useRefresh()
  const notify = useNotify()

  const { getValues } = useFormContext()

  const onSubmit = async (e, selectedRecord) => {
    e.preventDefault()
    let { name, key, displayType, titles, recommendType, type } = getValues()

    if (selectedRecord && selectedRecord.id) {
      update(
        'homepage-default-sections',
        {
          id: selectedRecord.id,
          data: {
            name,
            key,
            type,
            displayType,
            recommendType,
            titles,
          },
        },
        {
          onSuccess: () => {
            refresh()
            notify('Section update successfully')
          },
          onError: error => {
            notify(`${error.message}`, { type: 'error' })
          },
        },
      )
    } else {
      create(
        'homepage-default-sections',
        {
          data: {
            name,
            key,
            type,
            displayType,
            recommendType,
            titles,
          },
        },
        {
          onSuccess: () => {
            refresh()
            notify('Default section create successfully')
          },
          onError: error => {
            notify(`${error.message}`, { type: 'error' })
          },
        },
      )
    }
  }

  return (
    <Toolbar>
      <SaveButton type="button" onClick={e => onSubmit(e, selectedRecord)} />
      <RefreshButton onClick={() => setSelectedRecord(null)} />
    </Toolbar>
  )
}

const SectionDefaultAssignAside = ({ record, setSelectedRecord }) => {
  return (
    <Box sx={{ margin: '0.5rem', width: 400 }}>
      <Typography variant="body1">Create default section</Typography>
      <SimpleForm
        record={record}
        toolbar={
          <SectionDefaultAssignToolbar
            selectedRecord={record}
            setSelectedRecord={setSelectedRecord}
          />
        }
        resetOptions={{ keepDirtyValues: false }}>
        <SelectInput
          source="type"
          choices={SECTION_TYPES}
          fullWidth
          variant="standard"
        />
        <TextInput source="name" />
        <TextInput source="key" />
        <SelectInput
          source="recommendType"
          choices={RECOMMEND_TYPES}
          fullWidth
          variant="standard"
        />
        <ArrayInput label="Titles" source="titles">
          <SimpleFormIterator>
            <TextInput label="Title" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Box>
  )
}

const SegmentList = props => (
  <List
    {...props}
    perPage={1000}
    pagination={<HomepageSegmentPagination />}
    filters={<HomepageSegmentFilter />}
    sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <TextField source="name" />
      <FunctionField
        source="segmentType"
        render={record => SEGMENT_TYPES[record.segmentType]}
      />
      <FunctionField
        source="type"
        render={record => HOMEPAGE_TYPE[record.type]}
      />
      <ReferenceField label="Created by" source="createdBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Updated by" source="updatedBy" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
)

const TitlesField = () => {
  const record = useRecordContext()
  if (!record.titles || record.titles.length === 0) {
    return <></>
  }
  return (
    <ul>
      {record.titles.map(item => (
        <li>{item}</li>
      ))}
    </ul>
  )
}

const SectionDefaultList = () => {
  const [selectedRecord, setSelectedRecord] = useState(null)

  return (
    <List
      resource="homepage-default-sections"
      basePath="/homepage-default-sections"
      perPage={1000}
      actions={null}
      disableSyncWithLocation
      pagination={<HomepageSegmentPagination />}
      aside={
        <SectionDefaultAssignAside
          record={selectedRecord}
          setSelectedRecord={setSelectedRecord}
        />
      }
      sort={{ field: 'updatedAt', order: 'DESC' }}>
      <Datagrid
        bulkActionButtons={false}
        rowClick={(id, basePath, record) => {
          setSelectedRecord(record)
        }}>
        <TextField source="name" />
        <TextField source="key" />
        <TextField source="type" />
        <TextField source="recommendType" />
        <TitlesField label="Titles" />
        <DeleteButton undoable={false} redirect={false} />
      </Datagrid>
    </List>
  )
}

const HomepageSegmentList = () => (
  <List pagination={null}>
    <TabbedShowLayout
      record={{ id: 1, title: 'Hello world' }}
      syncWithLocation={false}>
      <TabbedShowLayout.Tab label="Segments">
        <SegmentList />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Sections" variant="filled" margin="normal">
        <SectionDefaultList />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </List>
)

export default HomepageSegmentList

import { useDataProvider, useNotify, useRedirect } from 'react-admin'

const useUpdateManyDataProvider = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()

  return async (resource, data, options = {}) => {
    if (!resource) {
      throw new Error('useCustomDataProvider: missing resource')
    }

    if (!data) {
      throw new Error('useCustomDataProvider: missing data')
    }

    try {
      const updatedData = data.data
      let res = null
      if (Array.isArray(updatedData)) {
        await Promise.all(
          updatedData.map(async item => {
            console.log(item)
            if (!item.id) {
              throw new Error('useCustomDataProvider: missing id')
            }
            res = await dataProvider.update(`${resource}`, {
              id: item.id,
              data: item,
            })
          }),
        )
      }

      if (options?.onSuccess) {
        notify(
          options.onSuccess?.notification?.body || 'Updated successfully',
          {
            type: options.onSuccess?.notification?.level || 'success',
          },
        )
      }

      if (options?.onSuccess?.redirectTo) {
        redirect(options.onSuccess.redirectTo || `/${resource}`)
      }

      return res
    } catch (e) {
      if (options.onFailure) {
        notify(
          options.onFailure?.notification?.body ||
            `${resource} not updated` + e?.message,
          {
            type: options.onFailure?.notification?.level || 'error',
          },
        )
      }
    }
  }
}

export default useUpdateManyDataProvider

import { Create } from 'react-admin'

import HomepageSegmentForm from './HomepageSegmentForm'

const HomepageSegmentCreate = props => (
  <Create redirect="list" {...props}>
    <HomepageSegmentForm />
  </Create>
)

export default HomepageSegmentCreate

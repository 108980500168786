import { Edit } from 'react-admin'

import HomepageSegmentForm from './HomepageSegmentForm'

const HomepageSegmentEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <HomepageSegmentForm editing />
    </Edit>
  )
}

export default HomepageSegmentEdit

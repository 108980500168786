import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ReferenceField,
  DateField,
  TextInput,
  NumberField,
  usePermissions,
} from 'react-admin'

import FilterComponent from '../../components/Filter'
import { ROLES } from '../../components/constants'
import CollectionCodeStatusField from '../../components/CollectrionCodeStatusField'

const CollectionFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </FilterComponent>
)

const CollectionList = props => {
  const { permissions } = usePermissions()
  console.log(permissions)
  return (
    <List
      {...props}
      perPage={1000}
      filters={<CollectionFilter />}
      sort={{ field: 'updatedAt', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="name" />
        <NumberField source="price" />
        <ReferenceField label="Created by" source="createdBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Updated by" source="updatedBy" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <CollectionCodeStatusField disabled={permissions !== ROLES.ADMIN} />
        <EditButton />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  )
}

export default CollectionList

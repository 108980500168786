import {
  Datagrid,
  DateField,
  DateInput,
  FunctionField,
  List,
  ReferenceField,
  SelectInput,
  TabbedShowLayout,
  useListContext,
} from 'react-admin'
import moment from 'moment'

import FilterComponent from '../../components/Filter'
import { FILTER_DATE } from '../../components/constants'
import { exportExcel } from '../../utils/exportExcel'

const CourseCustomFilter = props => {
  const { filterValues } = useListContext()
  return (
    <FilterComponent {...props}>
      <SelectInput
        label="Filter"
        source="date"
        choices={FILTER_DATE}
        alwaysOn
        allowEmpty={false}></SelectInput>
      {'custom' === filterValues.date && (
        <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
      )}
      {'custom' === filterValues.date && (
        <DateInput label="To date" source="toDate" alwaysOn></DateInput>
      )}
    </FilterComponent>
  )
}

const getSuggestionField = (record, field) => {
  if (!record || !record.suggestion) return null
  const suggestion = JSON.parse(record.suggestion)
  return suggestion[field]
}

const exporter = records => {
  const data = records.map(record => {
    return {
      Id: record.id,
      'Created at':
        record.createdAt && moment(record.createdAt).format('DD/MM/YYYY'),
      'User Id': record.userId,
      topic: getSuggestionField(record, 'topic'),
      mentor: getSuggestionField(record, 'mentor'),
    }
  })

  exportExcel({
    data,
    title: 'PodCourse suggestions',
    type: 'xlsx',
  })
}

const CourseSuggestionList = props => (
  <List
    {...props}
    filters={<CourseCustomFilter />}
    filterDefaultValues={{ entity: 'course' }}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    exporter={exporter}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <DateField source="createdAt" />
      <ReferenceField reference="users" source="userId" />
      <FunctionField
        source="suggestion"
        label="Chủ đề mong muốn"
        sortable={false}
        render={record => {
          return getSuggestionField(record, 'topic')
        }}
      />
      <FunctionField
        source="suggestion"
        label="Mentor mong muốn"
        sortable={false}
        render={record => {
          return getSuggestionField(record, 'mentor')
        }}
      />
    </Datagrid>
  </List>
)

const SuggestionList = () => (
  <List pagination={null}>
    <TabbedShowLayout record={{ id: 1, title: 'Hello world' }}>
      <TabbedShowLayout.Tab label="PodCourse">
        <CourseSuggestionList />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </List>
)

export default SuggestionList
